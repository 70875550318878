import React from 'react'
import { StyledFeaturedImageContainer, StyledTestimonialCta, StyledCtaText, StyledTextFlag, StyledButtonContainer, StyledButton, StyledContentContainer, StyledFeaturedImage } from './styles'

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function SmallTestimonialListing({
    module,
}) {
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            innerSpacing={module.enablePointer ? true : ''}
            data-name="testimonials_small-testimonials-listing"
        >
          
            <StyledContentContainer
                // type={'outer'}
            >    

                {module.testimonialCtas.map(({text, textFlag, featuredImage}, i) => (
                    <StyledTestimonialCta>
                        {featuredImage && 
                            <StyledFeaturedImageContainer className="cta-image-col">
                                <StyledFeaturedImage       
                                    alt={featuredImage.altText}
                                    imageFile={featuredImage.imageFile} 
                                />
                            </StyledFeaturedImageContainer>
                            
                        }

                        <div className="cta-text-col">


                            <StyledCtaText
                                // renderAs="p"
                                titleFontOptions={{ mobile: 'xs', desktop: 'xxs' }}
                            >
                                {text}
                            </StyledCtaText>

                            {textFlag && 
                                <StyledTextFlag 
                                    className="desktop"
                                    // dangerouslySetInnerHTML={{ __html: textFlag}}
                                    $backgroundColor={module.flagBackgroundColor}
                                    $textColor={module.flagTextColor}
                                >
                                    {textFlag}
                                </StyledTextFlag>
                            }
                        </div>

                        {textFlag && 
                            <StyledTextFlag 
                                className="mobile"
                                // dangerouslySetInnerHTML={{ __html: textFlag}}
                                $backgroundColor={module.flagBackgroundColor}
                                $textColor={module.flagTextColor}
                            >
                                {textFlag}
                            </StyledTextFlag>
                        }
                        
                    </StyledTestimonialCta>
                ))}

                    <StyledButtonContainer
                    >
                        {module.buttonLinkOne &&
                            <StyledButton 
                                buttonWrapperClass="styled-button-wrapper"
                                href={module.buttonLinkOne.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                            >
                                {module.buttonLinkOne.title}
                            </StyledButton>
                        }

                        {module.buttonLinkTwo &&
                            <StyledButton 
                                buttonWrapperClass="styled-button-wrapper"
                                href={module.buttonLinkTwo.url} 
                                buttonOptions={module.buttonOptions}
                                fontSize="md"
                            >
                                {module.buttonLinkTwo.title}
                            </StyledButton>
                        }
                        
                    </StyledButtonContainer>         
            </StyledContentContainer>
        </StyledModuleContainer>
    )
    return null;
}
