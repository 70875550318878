import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

const content_spacing = '25px';
const md_content_spacing = '35px';

const md_text_offset = '20px'
const lg_text_offset = '35px'

const alignText = css`
    text-align: ${props => props.$textAlign || ''};
`

export const StyledContentContainer = styled(StyledContainer)`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        justify-content: space-between;
    }
    
    /* flex-direction: ${props => props.$imageAlign && 
                props.$imageAlign !== 'image-right' ? 
                    'row' : 'row-reverse'}; */
`

export const StyledTestimonialCta = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;
    max-width: 380px;

    ${theme.mediaQuerys.smUp} {
        flex-direction: row-reverse;
        max-width: 325px;
    }

    ${theme.mediaQuerys.mdUp} {
        max-width: 460px;
        margin-bottom: 30px;
    }

    /* ${theme.mediaQuerys.lgUp} {
        max-width: 480px;
    } */


    .cta-image-col {
        width: 50%;

        ${theme.mediaQuerys.smUp} {
            width: 40%;
        }

        ${theme.mediaQuerys.mdUp} {
            width: 32%;
        }

        ${theme.mediaQuerys.lgUp} {
            width: 35%;
        }
    }

    .cta-text-col {
        /* width: 50%; */
        flex: 1;
        text-align: left;
        padding-left: 15px;
        padding-right: 0px;

        ${theme.mediaQuerys.smUp} {
            text-align: right;
            padding-left: 0px;
            padding-right: 15px;
        }
    }

    /* &:last-of-type {
        margin-bottom: 0;
    } */

    &:nth-of-type(2n) {
        flex-direction: row-reverse;

        ${theme.mediaQuerys.smUp} {
            flex-direction: row;
        }

        .cta-text-col {
            padding-left: 0px;
            padding-right: 15px;
            text-align: right;

            ${theme.mediaQuerys.smUp} {
                padding-left: 15px;
                padding-right: 0px;
                flex-direction: row;
                text-align: left;
            }
        }
    }
`

export const StyledCtaText = styled(ModuleTitle)`
    margin-bottom: 25px;
`

export const StyledTextFlag = styled(WysiwygContent)`
    background-color: ${props => props.$backgroundColor || theme.colors.white};
    color: ${props => props.$textColor || theme.colors.black};
    
    text-align: center;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin: 0 auto;

    p {
        font-size: 1.2rem;
        line-height: 16px;
    }
    
    ${theme.mediaQuerys.smUp} {
        max-width: calc(100% - 20px);
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 1.6rem;
        line-height: 21px;    */
        padding: 12px 12px 12px 12px;    
    }

    &.mobile {

        ${theme.mediaQuerys.mdUp} {
            display: none;
        }
    }

    &.desktop {
        display: none;

        ${theme.mediaQuerys.mdUp} {
            display: block;
        }
    }
`


export const StyledFeaturedImageContainer = styled.div`
    position: relative;
    margin: 0 auto ${content_spacing} auto;
`

export const StyledFeaturedImage = styled(WrImage)`
    margin: 0 auto;
`

export const StyledButtonContainer = styled.div`
    width: 100%;
    padding-top: 10px;

    ${theme.mediaQuerys.smUp} {
        display: flex;
        align-items: center;

    }

    .styled-button-wrapper {
        margin-bottom: 15px;

        ${theme.mediaQuerys.smUp} {
            padding-right: 10px;
            margin-bottom: 0;
        }

        ${theme.mediaQuerys.mdUp} {
            padding-right: 15px;
        }

        &:last-of-type {
            margin-bottom: 0;

            ${theme.mediaQuerys.smUp} {
                padding-right: 0px;
                padding-left: 10px;
            }

            ${theme.mediaQuerys.smUp} {
                padding-right: 0px;
                padding-left: 15px;
            }
        }
    }
`

export const StyledButton = styled(WrButton)`
    


    /* ${theme.mediaQuerys.smUp} {
        max-width: 450px;
        margin-bottom: 0;
    }

    ${props => props.$isImageRight && css`
        margin-left: auto;
    `} */
`
